.icon {
  &[data-size="small"] {
    inline-size: var(--icon-size-1);
  }

  &[data-size="medium"] {
    inline-size: var(--icon-size-2);
  }

  &[data-size="large"] {
    inline-size: var(--icon-size-3);
  }
}
