.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-neutral-opacity);
  z-index: var(--z-index-99);
}

.overlay .content {
  background: var(--color-bg);
  border-radius: var(--border-radius-1);
  box-shadow: var(--box-shadow-1);
  outline: none;
  display: flex;
  flex-direction: column;
  gap: var(--outer-spacing-4);
  padding-inline: var(--outer-spacing-4);
  padding-block: var(--outer-spacing-4);
  flex: 1;
}

.overlay .content .body {
  overflow: auto;
  padding-inline: var(--outer-spacing-4);
  margin-inline: calc(var(--outer-spacing-4) * -1);
}

.overlay [role="dialog"] {
  display: flex;
  max-inline-size: calc(100% - var(--outer-spacing-8));
  max-block-size: calc(100% - var(--outer-spacing-8));
}

.overlay [role="dialog"][data-size="small"] {
  inline-size: var(--sizing-120);
}

.overlay [role="dialog"][data-size="medium"] {
  inline-size: var(--sizing-180);
}

.overlay [role="dialog"][data-size="large"],
.overlay [role="dialog"][data-size="large"] .content {
  inline-size: 100%;
}

.overlay,
.content {
  transition-property: opacity;
}
.content[data-status="open"],
.content[data-status="close"],
.overlay[data-status="open"],
.overlay[data-status="close"] {
  /* don't forget to change the duration Modal.tsx as well */
  transition-duration: 200ms;
}
.content[data-status="initial"],
.content[data-status="close"],
.overlay[data-status="initial"],
.overlay[data-status="close"] {
  opacity: 0;
}
